<template>
    <div>
        <Breadcrumb titulo="Graficos" :items="itensBreadcrumb" class="color_produto" />
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-12 mb-5">
                    <div class="tabela-relatorios">
                        <div class="tabela-relatorios-wrapper">
                            <!-- FILTRO -->
                            <div class="container-fluid w-100 px-0">
                                <div class="row">
                                    <div class="d-flex col-xl-auto tabela-relatorios-filtros">
                                        <select-tabela v-model="graficoSelecionado"
                                                       :opcoes="opcoesGraficos"
                                                       :value="graficoSelecionado"
                                                       placeholder="Selecione..."
                                                       class=" mr-2"
                                                       @change="mudarOpcoesGrafico" />
                                        <select-tabela v-model="origemConsulta"
                                                       :opcoes="graficoSelecionado ==  0 ? opcoesConsultaDD : opcoesConsulta"
                                                       :value="origemConsulta"
                                                       placeholder="Selecione..."
                                                       class=" mr-2" />
                                        <input-data-tabela v-if="origemConsulta === '0'"
                                                           v-model="dataInicial"
                                                           placeholder="Data inicial"
                                                           class="tabela-relatorios-input-data mr-2" />
                                        <input-data-tabela v-if="origemConsulta === '0'"
                                                           v-model="dataFinal"
                                                           placeholder="Data final"
                                                           class="tabela-relatorios-input-data mr-2" />
                                        <select-tabela v-if="origemConsulta === '2'"
                                                       v-model="anoSelecionado"
                                                       :opcoes="opcoesAno"
                                                       :value="anoSelecionado"
                                                       placeholder="Selecione o ano"
                                                       class=" mr-2" />
                                        <select-tabela v-if="origemConsulta === '1'"
                                                       v-model="mesSelecionado"
                                                       :opcoes="opcoesMes"
                                                       :value="mesSelecionado"
                                                       placeholder="Selecione o mês"
                                                       class=" mr-2" />
                                        <div class="d-flex tabela-relatorios-acoes">
                                            <botao-padrao texto="Filtrar"
                                                          class="tabela-relatorios-botao-filtrar mx-3"
                                                          @click="buscarGrafico" />
                                        </div>
                                    </div>
                                    <div class="d-flex col tabela-relatorios-acoes">
                                        <!--<botao-padrao texto="Estatísticas" class="tabela-relatorios-botao-filtrar mr-2" @click="abrirEstatisticas" />
                                        <botao-padrao texto="Exportar relatório" class="tabela-relatorios-botao-exportar" @click="exportarRelatorioPorTipo" v-if="origem != 1" />-->
                                    </div>
                                </div>
                            </div>

                            <!-- DRILLDOWN -->
                            <div class="row app-custom-scroll" v-if="exibeSelecionado  == 0" style="width:99%; margin:0px auto">
                                <div class="col">
                                    <b-row>
                                        <b-col>Solicitações</b-col>
                                        <b-col v-for="mes in opcoesMes" class="text-truncate" :title="mes.texto"><b>{{mes.texto}}</b></b-col>
                                    </b-row>
                                    <b-row v-for="(dado, index) in tabelaDrillDown">
                                        <b-col>
                                            <b v-if="dado.isPrincipal" class="text-truncate" :title="dado.principal">{{dado.principal }}</b>
                                            <b-row v-for="(d, index) in dado.secundario" class="linhaDados">
                                                <b-col class="text-truncate celula_busca" :title="d.Titulo" @click="buscaNivel(1, d)">
                                                    {{d.Titulo}}
                                                </b-col>
                                                <b-col v-for="m in d.meses" :style="{'border':'1px solid','background-color':'#FFF', 'cursor':'pointer',   'font-weight':m.dados.length > 0 ?'bold': ''}" @click="buscaDestalhes(m)">
                                                    {{m.dados.length}}
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <!-- Detalhes DRILLDOWN -->
                            <div class="row app-custom-scroll" v-if="exibeSelecionado  == 50" style="width:99%; margin:0px auto">
                                <b-table :fields="detalhesColDrillDrown" :items="tabelaDetalhesDrillDown" striped hover style="background-color:#FFF; cursor: pointer"></b-table>
                            </div>

                            <!-- GRÁFICO - ÍNDICE GERAL -->
                            <b-row class="m-0 mt-2" v-if="exibeSelecionado == 1">
                                <b-col>
                                    <b-row>
                                        <b-col class="bg-white mb-3 text-center font-weight-bold p-1 font-size-13px">
                                            ÍNDICE GERAL
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="4" class="p-0">
                                            <div class="bg-white h-100 mr-4">
                                                <div class="text-center font-weight-bold py-3">
                                                    QUANTIDADE DE ATENDIMENTOS POR TABULAÇÃO
                                                </div>
                                                <table style="display: table; margin: 0px auto; width: 93%;">
                                                    <tr style="background: #ccc; font-size: 13px; font-weight: bold;">
                                                        <td>Tabulação</td>
                                                        <td>Quantidade</td>
                                                    </tr>
                                                    <tr v-for="item in dadosGraficoIndice" class="bg-white">
                                                        <td>{{item.nome}}</td>
                                                        <td>{{item.valor}}</td>
                                                    </tr>
                                                    <tr style="background: #ccc; font-size: 13px; font-weight: bold;">
                                                        <td>Total</td>
                                                        <td>{{qntTotalItensGrafico}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </b-col>
                                        <b-col cols="8" class="bg-white">
                                            <b-row>
                                                <b-col class="text-center font-weight-bold py-3">
                                                    GRÁFICO DE ATENDIMENTOS POR TABULAÇÃO
                                                </b-col>
                                            </b-row>
                                            <b-row align-h="center">
                                                <b-col cols="12" md="auto" class="mb-3">
                                                    <b-row align-h="center">
                                                        <b-col cols="12" md="auto">
                                                            <div v-for="(tab, index_tab) in dadosGraficoIndice"
                                                                 class="mb-3"
                                                                 style="display: inline-table">
                                                                <span class="tab_exibicao px-2" :style="{display:'inline-table', 'background-color':coresGrafico[index_tab],'text-align':'center','color':'#FFF','padding': '2px','font-size':'8px'}">
                                                                    <b style="font-size:10px;">{{tab.valor}}</b><br>
                                                                    {{tab.nome}}
                                                                </span>  &nbsp;
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col>
                                                            <Pie ref="graficoPizza" />
                                                        </b-col>
                                                    </b-row>
                                                    <b-row align-h="center">
                                                        <b-col cols="12" md="auto">
                                                            <div id="caixa_labels" style="display:inline-table; margin-top:10px;">
                                                                <div v-for="(item, index) in dadosGraficoIndice"
                                                                     style="display: inline-table">
                                                                    <span :style="{width:'10px',height:'10px', display:'inline-table', 'background-color':coresGrafico[index]}"></span>
                                                                    {{item.nome}}
                                                                    &nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <!-- GRÁFICO - SUB-ÍNDICE GERAL -->
                            <b-row v-if="exibeIndice && multiplosGraficos?.length"
                                   class="m-0 mt-3 bg-white">
                                <b-col class="caixa_subgrafico">
                                    <div v-for="(item, index) in multiplosGraficos" style="display:inline-table;margin:10px; width:20%">
                                        <span style="width: 100%; background-color: rgb(239, 239, 239); display: table; text-align: center; padding: 5px; margin: 5px 0px; font-size: 13px;">
                                            <b>{{item.Titulo}}</b>
                                        </span>
                                        <div id="caixa_tabs" style="display: inline-table; margin: 0px 0px 10px 0px; ">
                                            <div v-for="(tab, index_tab) in item.labels" class="mb-2" style="display: inline-table">
                                                <span class="tab_exibicao px-2" :style="{display:'inline-table', 'background-color':coresGrafico[index_tab],'text-align':'center','color':'#FFF','padding': '2px','font-size':'8px'}">
                                                    <b style="font-size:10px;">{{item.valores[index_tab]}}</b><br>
                                                    {{tab}}
                                                </span>  &nbsp;
                                            </div>
                                        </div>
                                        <div class="col" style="">
                                            <div style="width:150px !important; height:150px !important; display:table; margin:0px auto ">
                                                <Pie :ref="`graficoPizza-${index}`" :id="index" style="width:150px !important; height:150px !important;" />
                                            </div>
                                            <div id="caixa_labels" style="display:inline-table; margin-top:10px;">
                                                <div v-for="(l, index_l) in item.labels" style="display: inline-table">
                                                    <span :style="{width:'10px',height:'10px', display:'inline-table', 'background-color':coresGrafico[index_l]}"></span> {{l}} &nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>

                            <!-- GRÁFICO - NIVEL 3 (BARRAS) -->
                            <b-row v-if="exibeSelecionado == 2"
                                   class="m-0 mt-2">
                                <b-col class="p-0">
                                    <b-row>
                                        <b-col v-for="(item, index) in multiplosGraficos">
                                            <b-row>
                                                <b-col class="bg-white text-center py-1 mx-3 font-weight-bold font-size-13px">
                                                    {{item.tituloTopo}}
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col v-for="(grafico, index_g) in item.dadosGrafico"
                                                       class="m-3 px-2 py-3 text-center bg-white">

                                                    <HorizontalBar class="mt-2" :ref="`HorizontalBarNivel3-${index}${index_g}`" />
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <!-- GRÁFICO - ESTADOS -->
                            <b-row class="m-0 mt-2" v-if="exibeSelecionado == 3">
                                <b-col>
                                    <b-row>
                                        <b-col class="bg-white mb-3 text-center font-weight-bold p-1 font-size-13px">
                                            ESTADOS
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="4" class="p-0">
                                            <div class="bg-white h-100 mr-4 mb-3">
                                                <div class="text-center font-weight-bold py-3">
                                                    QUANTIDADE DE ATENDIMENTOS POR ESTADO
                                                </div>
                                                <table style="display: table; margin: 0px auto; width: 93%;">
                                                    <tr style="background: #ccc; font-size: 13px; font-weight: bold;">
                                                        <td>Estado</td>
                                                        <td>Quantidade</td>
                                                    </tr>
                                                    <tr v-for="(item, index) in labels" class="bg-white">
                                                        <td>{{item}}</td>
                                                        <td>{{valores[index]}}</td>
                                                    </tr>
                                                    <tr style="background: #ccc; font-size: 13px; font-weight: bold;">
                                                        <td>Total</td>
                                                        <td>{{valores.reduce((a, b) => a + b)}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </b-col>
                                        <b-col cols="8" class="bg-white">
                                            <b-row>
                                                <b-col class="text-center font-weight-bold py-3">
                                                    GRÁFICO DE ATENDIMENTOS POR ESTADO
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="mb-3">
                                                    <Bar ref="BarEstado" />
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <!-- GRÁFICO - TOTAIS -->
                            <b-row v-if="exibeSelecionado  == 4"
                                   class="m-0 mt-2">
                                <b-col class="p-0">
                                    <b-row>
                                        <b-col v-for="(item, index) in multiplosGraficos">
                                            <b-row>
                                                <b-col class="bg-white text-center font-weight-bold mx-3 p-1 font-size-13px">
                                                    {{item.Titulo}}
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="bg-white mt-2 mx-3">
                                                    <b-row align-h="center">
                                                        <b-col cols="12" md="auto" class="my-3">
                                                            <b-row>
                                                                <b-col>
                                                                    <div v-for="(tab, index_tab) in item.labels"
                                                                         class="mb-2"
                                                                         style="display: inline-table">
                                                                        <span class="tab_exibicao px-2"
                                                                              :style="{display:'inline-table', 'background-color':coresGrafico[index_tab],'text-align':'center','color':'#FFF','padding': '2px','font-size':'8px'}">
                                                                            <b style="font-size:10px;">{{item.valores[index_tab]}}</b><br>
                                                                            {{tab}}
                                                                        </span>  &nbsp;
                                                                    </div>
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <Pie :ref="`graficoPizzaTotais-${index}`" :id="index" />
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col class="mt-2">
                                                                    <div v-for="(l, index_l) in item.labels" style="display: inline-table">
                                                                        <span :style="{width:'10px',height:'10px', display:'inline-table', 'background-color':coresGrafico[index_l]}"></span> {{l}} &nbsp;&nbsp;
                                                                    </div>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <!-- GRÁFICO - FAMÍLIA -->
                            <b-row v-if="exibeSelecionado  == 5"
                                   class="m-0 mt-2">
                                <b-col class="p-0">
                                    <b-row>
                                        <b-col v-for="(item, index) in multiplosGraficos" v-if="item.valores.length" class="mx-3 mb-2 bg-white">
                                            <b-row>
                                                <b-col class="bg-white text-center font-weight-bold p-1 font-size-13px">
                                                    {{item.Titulo}}
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col style="background-color: var(--cinza-3); font-size: 7px;">
                                                    &nbsp;
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col class="bg-white mt-2 p-3 mb-2">
                                                    <b-row align-h="center">
                                                        <b-col cols="12" md="auto" style="max-width: 25vw;">
                                                            <b-row>
                                                                <b-col>
                                                                    <Pie :ref="`graficoPizzaFamilia-${index}`" :id="index" />
                                                                </b-col>
                                                            </b-row>
                                                            <b-row>
                                                                <b-col>
                                                                    <div v-for="(tab, index_tab) in item.labels" class="mt-2" style="display: inline-table">
                                                                        <span class="tab_exibicao px-2" :style="{display:'inline-table', 'background':coresGrafico[index_tab],'text-align':'center','color':'#FFF','padding': '2px','font-size':'8px'}">
                                                                            <b style="font-size:10px;">{{item.valores[index_tab]}}</b><br>
                                                                            {{tab}}
                                                                        </span>&nbsp;
                                                                    </div>
                                                                </b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <!-- GRÁFICO - TMA FAMÍLIA -->
                            <b-row v-if="exibeSelecionado  == 6" class="m-0 mt-2">
                                <b-col>
                                    <b-row>
                                        <b-col class="bg-white text-center font-weight-bold font-size-13px p-2 mb-2">
                                            TEMPO MÉDIO DE ATENDIMENTO - FAMÍLIA
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col class="bg-white py-3">
                                            <b-table :fields="labels" :items="valores" striped hover></b-table>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Pie } from 'vue-chartjs'
    import { HorizontalBar, Bar } from 'vue-chartjs'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import TabelaRelatorios from '@/components/tabelas/TabelaRelatorios.vue'
    import { mapActions } from 'vuex';
    import InputDataTabela from '@/components/inputs/InputDataTabela.vue';
    import SelectTabela from '@/components/inputs/SelectTabela.vue';
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue';
    import axios from 'axios'
    export default {
        name: 'RelatoriosPage',

        components: {
            Breadcrumb,
            TabelaRelatorios,
            InputDataTabela,
            SelectTabela,
            BotaoPadrao,
            Pie,
            HorizontalBar,
            Bar
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-chart-pie',
                        texto: 'Graficos',
                        link: ''
                    },
                ],
                fields_drillDrown: ['Principal', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                detalhesColDrillDrown: ['Ticket', 'Abertura', 'Status', 'Tempo Total', 'Cliente', 'Cidade', 'UF'],
                origemConsulta: '',
                opcoesGraficos: [
                    { valor: 0, texto: "DrillDown" },
                    { valor: 1, texto: "Indice geral" },
                    { valor: 2, texto: "Nivel 3 (barras)" },
                    { valor: 3, texto: "Estados" },
                    { valor: 4, texto: "Totais" },
                    { valor: 5, texto: "Familia" },
                    { valor: 6, texto: "TMA Familia" },

                ],
                opcoesConsulta: [{ valor: 0, texto: "Semana" }, { valor: 1, texto: "Mês" }, { valor: 2, texto: "Ano" },],
                opcoesConsultaDD: [{ valor: 2, texto: "Ano" }],
                dataInicial: null,
                dataFinal: null,
                opcoesAno: [],
                anoSelecionado: '',
                opcoesMes: [],
                mesSelecionado: null,
                graficoSelecionado: '',
                dadosGraficoIndice: [],
                retornoGrafico: null,
                qntTotalItensGrafico: 0,
                labels: [],
                valores: [],
                nivelDrillDown: 0,
                multiplosGraficos: [],
                tabelaDrillDown: [],
                tabelaDetalhesDrillDown: [],
                exibeIndice: false, exibeSelecionado: -1,
                chartData: '',
                options: {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
                coresGrafico: ['#34ace0', '#33d9b2', '#706fd3', '#2c2c54', '#ff5252', '#ffb142', '#84817a', '#cc8e35', '#ffda79', '#8B0000', '#4B0082', '#FFD700', '#9ACD32', '#7FFF00', '#F08080', '#708090']
            }
        },
        created() {
            this.gerarOpcoesAno();
            this.gerarOpcoesMes();
            this.buscarGraficosDisponiveis();
        },

        methods: {
            ...mapActions({
                buscaEnumsAtendimentos: 'atendimento/buscaEnums',
                buscaUsuarios: 'usuarios/buscaUsuarios'
            }),
            mudarOpcoesGrafico() {
                this.exibeSelecionado = -1;
            },
            gerarOpcoesAno() {
                var ano = new Date();
                for (var i = 0; i < 9; i++) {
                    this.opcoesAno.push({ valor: ano.getFullYear() - i, texto: ano.getFullYear() - i },)
                }
            },
            gerarOpcoesMes() {
                const mes = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
                for (var i = 0; i < 12; i++) {
                    this.opcoesMes.push({ valor: i + 1, texto: mes[i] },)
                }
            },
            async buscarGraficosDisponiveis() {
                var config = {
                    method: 'get',
                    url: '/api/relatorios/buscarGraficosDisponiveis',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    //data: data
                };
                await axios(config).then(response => {
                    this.opcoesGraficos = response.data
                    console.log("Responsennnn ", response.data)
                });
            },
            async buscaDestalhes(dados) {
                if (dados.conjuntoIds.length == 0) { return };

                this.tabelaDetalhesDrillDown = [];

                this.exibeSelecionado = 50;


                var data = {
                    RelarotioTipoExtras: 7,
                    Inicio: this.origemConsulta == 0 ? this.dataInicial : null,
                    Fim: this.origemConsulta == 0 ? this.dataFinal : null,
                    Ano: this.origemConsulta == 2 ? this.anoSelecionado : null,
                    Mes: this.origemConsulta == 1 ? this.mesSelecionado : 0,
                    IdConsulta: null,
                    GraficoTipo: 1,
                    IdsDrillDownDetalhes: dados.conjuntoIds
                };
                var config = {
                    method: 'post',
                    url: '/api/relatorios/RelatoriosExtras',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                $("#caixa_carregamento").fadeIn();
                await axios(config).then(response => {
                    this.retornoGrafico = response.data.List;
                    let res = response.data.List;
                    let padroes = res[0].Retorno.map(m => {
                        return Object.assign({
                            Ticket: m.Protocolo,
                            Abertura: m.Abertura,
                            Status: m.Status,
                            "Tempo Total": this.formatarTempo(m.Tempo),
                            Cliente: m.Cliente,
                            Cidade: m.Cidade,
                            UF: m.UF
                        }, ...m.Tabulacoes.map(item => ({ [item.Titulo]: item.RespostaItemTitulo })))

                            ;
                    });
                    for (var i in res[0].Retorno) {
                        for (var d in res[0].Retorno[i]?.Tabulacoes) {
                            if (!this.detalhesColDrillDrown.includes(res[0].Retorno[i].Tabulacoes[d]?.Titulo)) {
                                this.detalhesColDrillDrown.push(res[0].Retorno[i]?.Tabulacoes[d]?.Titulo)
                            }
                        }

                    }
                    this.tabelaDetalhesDrillDown = padroes
                    $("#caixa_carregamento").fadeOut();
                }).catch(() => {
                    $("#caixa_carregamento").fadeOut();
                    this.$bvToast.toast(`Não foi possível efetuar a consulta no momento. Se o problema persistir, entre em contato com o suporte tecnico.`, {
                        title: "Erro",
                        toaster: "b-toaster-top-right",
                        solid: true,
                        variant: "danger",
                        appendToast: false,
                        autoHideDelay: 5000
                    });
                });
            },
            async buscarGrafico() {
                let dadosConsultaGrafico = '';
                let grafico = '';
                let opcoesTeste = {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
                this.labels = [];
                this.valores = [];
                this.dadosGraficoIndice = [];
                this.multiplosGraficos = [];
                this.qntTotalItensGrafico = 0;
                this.exibeSelecionado = -1;
                this.exibeIndice = false;
                this.nivelDrillDown = 0;

                var data = {
                    RelarotioTipoExtras: this.graficoSelecionado,
                    Inicio: this.origemConsulta == 0 ? this.dataInicial : null,
                    Fim: this.origemConsulta == 0 ? this.dataFinal : null,
                    Ano: this.origemConsulta == 2 ? this.anoSelecionado : null,
                    Mes: this.origemConsulta == 1 ? this.mesSelecionado : 0,
                    IdConsulta: null,
                    GraficoTipo: 1
                };
                var config = {
                    method: 'post',
                    url: '/api/relatorios/RelatoriosExtras',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                $("#caixa_carregamento").fadeIn();
                await axios(config).then(response => {
                    this.retornoGrafico = response.data.List;
                    grafico = this.retornoGrafico[0].Retorno;
                    dadosConsultaGrafico = response.data.List;


                    $("#caixa_carregamento").fadeOut();
                }).catch(() => {
                    $("#caixa_carregamento").fadeOut();
                    this.$bvToast.toast(`Não foi possível efetuar a consulta no momento. Se o problema persistir, entre em contato com o suporte tecnico.`, {
                        title: "Erro",
                        toaster: "b-toaster-top-right",
                        solid: true,
                        variant: "danger",
                        appendToast: false,
                        autoHideDelay: 5000
                    });
                });
                if (this.graficoSelecionado == '0') {
                    this.tabelaDrillDown = [];
                    for (var i in grafico) {
                        let dadosSecundarios = [];
                        for (var d in grafico[i].Secundario) {
                            let qntSecundarioMes = [];
                            for (var mes in this.opcoesMes) {
                                let informacao = grafico[i].Secundario[d].Dados.filter(f => f.Month == this.opcoesMes[mes].valor);

                                let v =
                                {
                                    texto: this.opcoesMes[mes].texto,
                                    dados: informacao,
                                    quantidade: informacao.length,
                                    conjuntoIds: informacao.map(item => { return item.Id })
                                };

                                qntSecundarioMes.push(v)
                            }
                            dadosSecundarios.push({ Titulo: grafico[i].Secundario[d].Titulo, meses: qntSecundarioMes, id: grafico[i].Secundario[d].TabulacaoItemId });
                        }

                        this.tabelaDrillDown.push({ principal: grafico[i].Titulo, secundario: dadosSecundarios, isPrincipal: true })
                    }
                    this.exibeSelecionado = this.graficoSelecionado;
                }
                if (this.graficoSelecionado == '1') {

                    this.exibeIndice = true;
                    this.exibeSelecionado = this.graficoSelecionado;
                    for (var i in grafico) {
                        this.labels.push(grafico[i].Titulo);
                        this.valores.push(grafico[i].Dados.length);
                        this.dadosGraficoIndice.push({ nome: grafico[i].Titulo, valor: grafico[i].Dados.length });
                        this.qntTotalItensGrafico += grafico[i].Dados.length;
                        this.chartData = {
                            labels: this.labels,
                            datasets: [{
                                borderWidth: 0,
                                borderColor: this.coresGrafico,
                                backgroundColor: this.coresGrafico,
                                data: this.valores,
                            }]
                        };
                        this.options.onClick = (e, tooltipItems) => {
                            data.RelarotioTipoExtras = 1;
                            data.IdConsulta = dadosConsultaGrafico[0].Retorno[tooltipItems[0]._index].TabulacaoId;
                            data.GraficoTipo = 2;
                            data.Inicio = this.dataInicial;
                            data.Fim = this.dataFinal;
                            $("#caixa_carregamento").fadeIn();
                            axios(config).then(response => {

                                this.gerar(response.data.List, opcoesTeste);
                                $("#caixa_carregamento").fadeOut();
                                this.$nextTick(() => $(".main.app-scroll-custom").get(0).scrollTo({ top: document.body.scrollHeight, behavior: "smooth" }));
                            });
                            return;
                        };
                        this.$nextTick(() => {
                            this.$refs.graficoPizza.renderChart(this.chartData, this.options);
                        });
                    }
                }
                if (this.graficoSelecionado == '2') {
                    this.exibeSelecionado = this.graficoSelecionado;
                    for (var i in grafico) {
                        let dadosGrafico = [];
                        this.labels = [];
                        this.valores = [];
                        for (var e in grafico[i].Produtos) {
                            this.labels.push(grafico[i].Produtos[e].Titulo);
                            this.valores.push(grafico[i].Produtos[e].Quantidade);
                        }
                        dadosGrafico.push({ tituloGrafico: grafico[i].Titulo, labels: this.labels, valores: this.valores })
                        this.multiplosGraficos.push({ tituloTopo: grafico[i].Titulo, dadosGrafico: dadosGrafico });
                    }
                    this.$nextTick(() => {
                        for (var item in this.multiplosGraficos) {
                            for (var i in this.multiplosGraficos[item].dadosGrafico) {
                                this.$refs[`HorizontalBarNivel3-${item}${i}`][0].renderChart({
                                    labels: this.multiplosGraficos[item].dadosGrafico[i].labels, datasets: [{
                                        borderWidth: 0,
                                        borderColor: this.coresGrafico,
                                        backgroundColor: this.coresGrafico,
                                        data: this.multiplosGraficos[item].dadosGrafico[i].valores,
                                    }]
                                }, opcoesTeste);
                            }
                        }
                    });
                }
                if (this.graficoSelecionado == '3') {

                    this.exibeSelecionado = this.graficoSelecionado;
                    this.labels = [];
                    this.valores = [];
                    for (var i in grafico) {

                        this.coresGrafico.push(this.generateColor())
                        this.labels.push(grafico[i].Titulo);
                        this.valores.push(grafico[i].Quantidade);
                    }
                    this.$nextTick(() => {
                        this.$refs.BarEstado.renderChart({
                            labels: this.labels,
                            datasets: [{
                                borderWidth: 0,
                                borderColor: this.coresGrafico,
                                backgroundColor: this.coresGrafico,
                                data: this.valores,
                            }]
                        }, opcoesTeste);
                    });
                }
                if (this.graficoSelecionado == '4') {
                    this.exibeSelecionado = this.graficoSelecionado;

                    for (var i in dadosConsultaGrafico[0].Retorno) {
                        this.labels = [];
                        this.valores = [];
                        //itens é labes
                        for (var d in dadosConsultaGrafico[0].Retorno[i].Dados) {
                            //
                            this.labels.push(dadosConsultaGrafico[0].Retorno[i].Dados[d].TituloItem);
                            this.valores.push(dadosConsultaGrafico[0].Retorno[i].Dados[d].Dados.length);
                        }
                        this.multiplosGraficos.push({ Titulo: dadosConsultaGrafico[0].Retorno[i].NomeTabulacao + " " + dadosConsultaGrafico[0].Retorno[i].Nome, labels: this.labels, valores: this.valores });
                    }
                    this.$nextTick(() => {
                        for (var item in this.multiplosGraficos) {
                            this.$refs[`graficoPizzaTotais-${item}`][0].renderChart({
                                labels: this.multiplosGraficos[item].labels, datasets: [{
                                    borderWidth: 0,
                                    borderColor: this.coresGrafico,
                                    backgroundColor: this.coresGrafico,
                                    data: this.multiplosGraficos[item].valores,
                                }]
                            }, opcoesTeste);
                        }
                    });

                }
                if (this.graficoSelecionado == '5') {
                    
                    this.exibeSelecionado = this.graficoSelecionado;
                    for (var i in grafico) {
                        this.labels = [];
                        this.valores = [];
                        for (var d in grafico[i].Dados) {
                            this.labels.push(grafico[i].Dados[d].Titulo);
                            this.valores.push(grafico[i].Dados[d].Quantidade);
                        }
                        this.multiplosGraficos.push({ Titulo: grafico[i].Titulo, labels: this.labels, valores: this.valores });
                    }
                    this.$nextTick(() => {
                        let familiaOption = {
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                        }
                        for (var item in this.multiplosGraficos) {

                            this.$refs[`graficoPizzaFamilia-${item}`][0].renderChart({
                                labels: this.multiplosGraficos[item].labels, datasets: [{
                                    borderWidth: 0,
                                    borderColor: this.coresGrafico,
                                    backgroundColor: this.coresGrafico,
                                    data: this.multiplosGraficos[item].valores,
                                }]
                            }, familiaOption);
                        }
                    });
                }
                if (this.graficoSelecionado == '6') {
                    this.exibeSelecionado = this.graficoSelecionado;
                    this.labels = ["+", ...grafico[0].Dados.map(item => item.TituloItem)];
                    this.valores = grafico.map(item => [
                        `${item.NomeTabulacao.trim()} ${item.Nome.trim()}`,
                        ...item.Dados.map(item => this.formatarTempo(item.Media))
                    ]).map(item => Object.assign({}, ...item.map((value, key) => ({ [this.labels[key]]: value }))));
                }
            },
            formatarTempo(tempo) {

                if (!tempo) { return "-" };
                tempo = tempo.split(":");
                let dias = tempo[0].split(".").length == 2 ? parseInt(tempo[0].split(".")[0]) : 0,
                    horas = parseInt(tempo[0].split(".").slice(-1)),
                    minutos = parseInt(tempo[1]),
                    segundos = Math.floor(parseFloat(tempo[2])),
                    acumulado = 0,
                    marks = "dhms",
                    tempoParts = [dias, horas, minutos, segundos].map((item, index) => {
                        acumulado += item;
                        return acumulado == 0 ? "" : item.toString() + marks[index];
                    });
                return acumulado > 0 ? tempoParts.join(" ") : "—";
            },
            gerar(valor, opcoesTeste) {

                this.multiplosGraficos = [];
                for (var i in valor[0].Retorno) {
                    this.labels = [];
                    this.valores = [];
                    //itens é labes
                    for (var d in valor[0].Retorno[i].Itens) {
                        //
                        this.labels.push(valor[0].Retorno[i].Itens[d].Nome);
                        this.valores.push(valor[0].Retorno[i].Itens[d].Dados.length);
                    }
                    this.multiplosGraficos.push({ Titulo: valor[0].Retorno[i].Titulo, labels: this.labels, valores: this.valores });
                }
                this.$nextTick(() => {
                    for (var item in this.multiplosGraficos) {
                        this.$refs[`graficoPizza-${item}`][0].renderChart({
                            labels: this.multiplosGraficos[item].labels, datasets: [{
                                borderWidth: 0,
                                borderColor: this.coresGrafico,
                                backgroundColor: this.coresGrafico,
                                data: this.multiplosGraficos[item].valores,
                            }]
                        }, opcoesTeste);
                    }
                });
            },
            generateColor() {
                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            },

            async buscaNivel(nivel, dados) {
                var data = {
                    RelarotioTipoExtras: this.graficoSelecionado,
                    Inicio: this.dataInicial,
                    Fim: this.dataFinal,
                    Ano: this.anoSelecionado,
                    Mes: this.mesSelecionado,
                    IdConsulta: dados.id,
                    GraficoTipo: 1,
                    Nivel: nivel
                };
                var config = {
                    method: 'post',
                    url: '/api/relatorios/RelatoriosExtras',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                $("#caixa_carregamento").fadeIn();
                await axios(config).then(response => {
                    let niveis = response.data.List[0].Retorno;
                    if (niveis.length == 0) { return };
                    this.tabelaDrillDown = [];
                    for (var i in niveis) {
                        let dadosSecundarios = [];
                        let qntSecundarioMes = [];
                        for (var mes in this.opcoesMes) {
                            let informacao = niveis[i].Dados.filter(f => f.Month == this.opcoesMes[mes].valor);
                            let v =
                            {
                                texto: this.opcoesMes[mes].texto,
                                dados: niveis[i].Dados.filter(f => f.Month == this.opcoesMes[mes].valor),
                                quantidade: niveis[i].Dados.filter(f => f.Month == this.opcoesMes[mes].valor).length,
                                conjuntoIds: informacao.map(item => { return item.Id })
                            };
                            qntSecundarioMes.push(v)
                        }
                        dadosSecundarios.push({ Titulo: niveis[i].Titulo, meses: qntSecundarioMes, id: niveis[i].TabulacaoItemId });
                        this.tabelaDrillDown.push({ principal: niveis[i].Titulo, secundario: dadosSecundarios, isPrincipal: false })


                    }
                    $("#caixa_carregamento").fadeOut();
                }).catch(() => {
                    $("#caixa_carregamento").fadeOut();
                    this.$bvToast.toast(`Não foi possível efetuar a consulta no momento. Se o problema persistir, entre em contato com o suporte tecnico.`, {
                        title: "Erro",
                        toaster: "b-toaster-top-right",
                        solid: true,
                        variant: "danger",
                        appendToast: false,
                        autoHideDelay: 5000
                    });
                });
                this.nivelDrillDown += nivel;
            }
        }
    }
</script>

<style scoped>
    .color_produto {
        background-color: #a1c9ff;
        color: #000 !important;
    }

    .consulta-atendimentos-page-tabela {
        height: calc(100vh - 180px);
    }

    .caixa_grafico {
        width: 60%;
        height: 60%;
        display: table;
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
        margin: 0px auto;
    }

    .tab_exibicao {
        box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    }

    .caixa_subgrafico {
        display: table;
        margin: 0px auto;
        margin-bottom: 50px;
    }

    .titulo_grafico_nivel3 {
        color: darkred;
        font-size: 14px;
        text-align: center;
    }

    .caixa_multipla_nivel3 {
        display: inline-table;
        margin: 10px;
        width: 20%;
    }

    .celula_busca {
        border: 1px solid;
        background-color: #FFF;
        cursor: pointer;
    }

        .celula_busca:hover {
            background-color: #e8e8e8;
        }

    .font-size-13px {
        font-size: 13px;
    }
</style>