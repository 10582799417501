<template>

    <b-container style="max-width:100%; height: 500px" fluid>
        <b-row>
            <Breadcrumb titulo=" Histórico de alteração de usuarios"
                        :items="itemsBreadcrumb" />
        </b-row>

        <b-row class="mx-0 my-0" style="min-height: 580px;">
            <b-col class="p-0" style="margin:5px;  background-color: #e8e8e8">
                <b-row class="mx-0 my-0">
                    <b-col>
                        <b-row align-h="between">
                            <b-col cols="12" md="auto" class="my-3 font-weight-bold">
                                <b-button  size="sm" variant="outline-secondary" class="rounded-0 px-3 py-1 mr-3" @click="voltarUsuario">
                                    Voltar
                                </b-button>
                                Registro de alteração de usuário  em:
                            </b-col>
                            <b-col cols="12" md="auto" class="my-2">
                                <select class="font-weight-bold" @change="TrocaHistorico($event)" v-model="mesAtual">
                                    <option :value="index" v-for="(item, index) in labels">{{item}} {{ new Date().getFullYear() }}</option>
                                </select>
                            </b-col>
                        </b-row>
                        <div id="caixa_quantidade">
                            Total de usuários ativos no mês: <span style="font-weight:bold; font-size:20px;">{{quantidadeTotal}}</span>
                        </div>
                        <div id="caixa_dados" class="app-scroll-custom" style="max-height:400px; background-color:#FFF;margin-top:15px">
                            <b-table striped hover :items="dadosPico" :fields="fieldsPico" style="font-size:12px">
                                <template #cell(DataCadastro)="data">
                                    {{data.item.DataCadastro}}
                                    <i class="fas fa-trash-alt float-right ml-2 P-icons" @click='excluirPrompt(data)'></i>
                                    <i class="fas fa-pen float-right ml-2 P-icons" @click='editarPrompt(data)'></i>
                                </template>
                            </b-table>
                        </div>

                    </b-col>
                    <b-col>
                        <div style="background-color:#FFF; margin-top:40px;padding:15px; height:92%">
                            <Bar ref="BarEstado" />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
    import axios from 'axios'
    import { Bar } from 'vue-chartjs'
    export default {
        name: 'HistoricoPico',
        components: {
            Bar, Breadcrumb
        },
        data: function () {
            return {
                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Usuarios',
                        link: '/usuarios'
                    },
                    {
                        id: '3',
                        classe: 'fa fa',
                        texto: 'Historico de usuarios',
                        link: '/gerenciarformularios'
                    }
                ],
                coresGrafico: ['#34ACE0', '#33D9B2', '#706FD3', '#2C2C54', '#FF5252', '#FFB142', '#84817a', '#CC8E35', '#FFDA79', '#8B0000', '#4B0082', '#FFD700', '#9ACD32', '#7FFF00', '#F08080', '#708090'],
                dadosPico: null,
                fieldsPico: [
                    { key: 'Origem', label: "Ação" },
                    { key: 'NomeUsuario', label: "Usuário" },
                    { key: 'UsuarioAcao', label: "Realizado por" },
                    { key: 'DataAcao', label: "Data da ação" },

                ],
                labels: [
                    'Jan',
                    'Fev',
                    'Mar',
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                    'Dez'
                ],
                valores: [],
                options: {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
                quantidadeTotal: 0,
                mesAtual: 0
            }
        },
        created() {
            this.buscaHistorico(13);

        },
        methods: {
            buscaHistorico(mes) {
                $("#caixa_carregamento").show();
                let mesConsulta = (mes == 13) ? 0 : (mes + 1)
                axios.get('api/relatorios/relatorioUsuarioPico/' + mesConsulta)
                    .then((response) => {

                        this.dadosPico = response.data.Dados;
                        this.mesAtual = response.data.MesConsulta -1 ;
                        
                        this.quantidadeTotal = response.data.QuantidadeTotal;
                        this.options.onClick = (e, tooltipItems) => {
                            this.buscaHistorico(tooltipItems[0]._index)
                        }
                        this.$refs.BarEstado.renderChart({
                            labels: this.labels, datasets: [{
                                borderWidth: 0,
                                borderColor: this.coresGrafico,
                                backgroundColor: this.coresGrafico,
                                data: response.data.QuantidadeMeses,
                            }]
                        },
                            this.options
                        );
                        $("#caixa_carregamento").hide();

                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },
            TrocaHistorico(e) {
                console.log("Evento: ", e.srcElement.value)
                this.buscaHistorico(parseInt(e.srcElement.value));

            },
            voltarUsuario() {
                this.$router.push('/usuarios')
            }
        },

    }
</script>



<style scoped>
    #caixa_quantidade {
        background-color: #FFF;
        padding: 15px;
        text-align: center;
    }
</style>